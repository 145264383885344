#filter{
  /* min-height: 100vh; */
  background: white;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  padding: 30px 10px;
  margin-bottom: 30px;
  /* margin-bottom: 10px; */
  position: relative;
}

#filter label {
  margin-bottom: 5px;
}

#filter select, input[type="date"] {
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #ced4da;
  color: #495057;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  margin-bottom: 25px;
}

.dropdownSelect {
  width: 98%;
  margin-bottom: 30px;
}

