#login {
  /* min-height: 100vh !important; */
  height: 100%;
  background-color: white;
}

#login .login-inner {
  font-size: 18px;
  display: flex;
  height: 85%;
  /* background: white;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
}

#login .logo {
  margin: auto;
  color: black;
  display: none;
}

#app .headerForLogin {
  background-color: white;
  height: 15%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

#login .login-form {
  width: 100%;
  /* padding: 5% 20px 10px 7%; */
  display: flex;
  /* align-items: center; */
  justify-content: center;
  padding: 50px 0 50px 0;
  background-color: #f8f9fa;
  border-radius: 5px;
}
#login .login-form .login-form-inner {
  width: 90%;
} 

@media screen and (min-width: 768px) {
  #login{
    /* padding:10% 14% 10% 14%; */
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #login .login-form .login-form-inner {
    width: 60%;
    height: max-content;
    /* width: 80%; */
  }
  #login .login-inner {
    /* height: auto !important; */
    width: 100%;
    min-height: 100vh;
    height: max-content;
  }
  #app .headerForLogin {
    display: none;
  }
  #login .logo {
    display: block;
  }
  #login .login-form {
    width: 50% !important;
    align-items: center;
  }
  #login .title {
    margin: 20px 10px 30px 10px;
  }
  #login input[type="text"],
  #login input[type="password"] {
    /* width: 85% !important; */
  }
}

#login .input-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 10px;
  position: relative;
  font-weight: 500;
  font-size: 16px;
}

#login input[type="text"],
#login input[type="password"] {
  /* width: 87%; */
  padding: 7px 16px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
#login .userIcon {
  position: absolute;
  top: 60px;
  right: 20px;
}

#login .showPassword {
  position: absolute;
  top: 58px;
  right: 10px;
  border: none;
  background: transparent;
  border-radius: 50%;
  padding: 4px 8px;
}

#login .showPassword:hover {
  background: #ddd;
}

#login .remember {
  margin: 15px 10px 15px 15px;
}

#login input[type="submit"] {
  margin: 10px;
  cursor: pointer;
  border-radius: 5px;
  background: #fde428;
  width: 95%;
  border: none;
  padding: 7px 20px;
  font-size: 20px;
}

#login .error {
  color: red;
  margin: 0 0 0 10px;
}
