#filterPage {
    padding: 20px;
    background: #f0f0f0;
    min-height: 88vh;
    /* text-align: center; */
  }
  
  #filterPage .card a {
    text-decoration: none;
    color: #005b89;
  }
  #filterPage .card a:hover {
    text-decoration: underline;
    color: #0d6efd;
  }
  #filterPage .card {
    padding: 10px 15px 10px 15px;
    background: white;
    position: relative;
    max-width: 480px;
    margin: 10px;
    /* border: 1px solid #ddd; */
    text-align: left;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    /* box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3); */
  }
  
  #filterPage .card .card-id-btn {
    text-align: center;
    font-size: 21px;
    font-weight: 600;
    cursor: pointer;
    background: #eee;
    border-radius: 8px;
    margin: 5px 10px;
  }
  
  #filterPage .card .card-id-btn:hover {
    background: #ddd;
  }
  
  #filterPage .card .active-card-id:hover {
    background-color: #fde428c9;
    color: #424242;
  }
  #filterPage .card .active-card-id {
    background-color: #fde428;
  }
  #filterPage .card .text2 {
    color: #424242;
  }
  
  #filterPage .total-result {
    /* margin: 30px 0; */
    margin-bottom: 20px;
    position: relative;
    min-height: 120px;
    background-color: white;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    /* border: 1px solid #ddd; */
    border-radius: 5px;
  }
  #filterPage .total-result .select-all-label {
    padding: 0 10px;
    cursor: pointer;
    border-radius: 5px;
  }
  #filterPage .total-result .select-all-label:hover {
    background: #fff;
  }
  #filterPage .total-result button {
    height: 40px;
    margin-top: 15px;
  }
  #filterPage .total-result input[type="number"],
  .total-result select {
    padding: 5px;
    cursor: pointer;
    width: 180px;
    border-radius: 5px;
    border: 1px solid #ced4da;
    color: #495057;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }
  
  #filterPage .tableIMG {
    border: 1px solid #666666;
    border-radius: 5px;
    margin: auto;
  }
  
  #filterPage .download-button {
    padding: 10px 0 20px 0;
    width: 100%;
  }
  #filterPage .download-button span {
    margin: 0 10px 0 10px;
  }
  
  #filterPage .download-button button {
    margin: 10px 50px;
    width: 175px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  


  #filterPage .filterButton {
    margin: 20px 20px 0 20px;
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
  
  #filterPage .tableCheckbox {
    position: absolute;
    top: 40px;
    left: 12px;
    /* left: 25px; */
  }
  
  
  #filterPage .filter-pagination {
    padding: 15px 0;
    width: max-content;
    margin: auto;
    margin-top: 10px;
    /* background-color: rgb(239, 239, 239); */
    font-size: 20px;
    border-radius: 5px;
  }
  @media screen and (min-width: 768px) {
  
    #filterPage .total-result {
      min-height: 70px !important;
    }
    #filterPage .download-button {
      /* width: max-content;
      text-align: center; */
      display: flex;
      /* justify-content: center; */
    }
    #filterPage .filterButton {
      margin: 5px 20px 0 20px !important;
      flex-direction: row !important;
    }

  }
  