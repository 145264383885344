@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Scrollbar */
/* Change scrollbar width */
::-webkit-scrollbar {
  width: 12px;
  border: 1px solid #ddd;
}

/* Change scrollbar color */
::-webkit-scrollbar-thumb {
  /* background-color: #c1c1c1; */
  background-color: #fde428;
}

body {
  font-family: Arial, sans-serif;
  height: 100vh;
  /* background-color: #f0f0f0; */
} 
#root{
  height: 100vh;
  /* height: auto; */
}
#app {
  min-height: 100vh;
  height: inherit;
  /* height: max-content; */
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  /* font-family: monospace; */
  /* background-color: #f0f0f0; */
}

#app .logout {
  float: right;
  margin: 30px 50px;
}

#app input[type="checkbox"] {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

#app .list-container {
  display: flex;
}

#header404 {
  background-color: white;
  height: 15%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

/*
 html form
 
 body {
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
} */
