#header {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
#header .widget-heading {
  font-size: 16px;
  line-height: 23px;
  color: #1a202c;
  /* display: none; */
    display: flex ;
    align-items: center;
}

#header .widget-content {
  float: right;
  margin: 20px 10px 10px 0px;
}
#header .widget-content button:active {
  border: none !important;
}

#header .logout {
  float: right;
  margin: 30px 50px;
}
#header .user-detail-box1 {
  background: #f4f5f9;
  padding: 3px;
  border: 0.5px solid rgba(106, 110, 131, 0.2);
  border-radius: 45px !important;
}

@media screen and (min-width: 768px) {
  #header {
    padding-left: 50px !important;
  }
  /* #header .widget-heading {
    display: flex !important;
    align-items: center;
  } */
  #header .widget-content {
    margin: 20px 50px 10px 30px !important;
  }
  /* #header .user-detail-box1 {
    background: #f4f5f9;
    padding: 3px;
    border: 0.5px solid rgba(106, 110, 131, 0.2);
    border-radius: 45px !important;
  } */
}
